import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Carousel, Flex, Image, Tooltip} from "antd";
import Header2 from "../../components/Header/header2";
import axios from "axios";
import {DOMAIN} from "../../constant";
import {nanoid} from "nanoid";
import Star from "../../components/Fun/Background/star/star";

import './Crew.css'
import Extend from "../../components/Fun/Button/extend/Extend";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

//定义UI组件
class Crew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                types: [],
                times: [],
                img: {},
                slogan: {},
                detail: {},
            },
            data2: [],
            time: '',
            type: 1,
            chunk: [],
            color: "",
        };
    }


    chunkArray = (array, size, max) => {
        let result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    getColor = (type) => {
        let color
        switch (type) {
            case 1:
                color = "#16b8f3"
                break;
            case 2:
                color = "#008F8E"
                break;
            case 3:
                color = "#900021"
                break;
            case 4:
                color = "#F6D179"
                break;
            case 5:
                color = "#D44848"
                break;
            case 6:
                color = "#470009"
                break;
            case 7:
                color = "#0361cc"
                break;
            case 8:
                color = "#006100"
                break;
            case 9:
                color = "#fc813b"
                break;
            case 10:
                color = "#a13a00"
                break;
        }
        return color;
    }

    searchCrew = (type, time) => {
        let color = this.getColor(type)
        axios.get(DOMAIN + '/api/official/crew?type=' + type + '&time=' + time)
            .then(r => {
                const data2 = r.data.data
                // console.log('搜索crew成功=>', r.data.data)
                let chunk = this.chunkArray(data2, 10, 50);
                this.setState({data2, type, time, chunk, color})
            })
            .catch(e => {
                console.log('搜索crew失败=>', e)
            })
    }

    componentDidMount() {
        document.title = "船员"
        axios.get(DOMAIN + '/api/official/crewHome')
            .then(r => {
                const data = r.data.data
                // console.log('crew成功=>', r.data.data)
                this.searchCrew(1, data.times[data.times.length - 1])
                this.setState({data})
            })
            .catch(e => {
                console.log('crew失败=>', e)
            })
    }

    render() {

        const {data, data2, type, time, chunk, color} = this.state
        const {types, times, img, slogan, detail} = data

        return (
            <div>
                <Header2 defaultKey={"/crew"}/>
                <Star></Star>

                {/*年份*/}
                <Flex className={"CrewContent1"} justify={"space-evenly"} align={"center"}>
                    {times.map(t => {
                        return <Extend className={time === t ? "extendActive" : ""} key={nanoid()} content={t}
                                       onClick={() => {
                                           this.searchCrew(type, t)
                                       }}></Extend>
                    })}
                    <a href={"/"}><img className={"CrewIcon"} src={img.resource}/></a>
                </Flex>

                <Flex className={"CrewContent2"} justify={"space-evenly"} align={"center"}>

                    {/*等级*/}
                    <Flex vertical={true} justify={"space-evenly"}>
                        <ul className={"CrewUl"}>
                            {types.map(t => {
                                if (t.value != null) {
                                    return <li key={nanoid()} style={{"--c": this.getColor(t.value)}}>
                                        <a data-text={t.name} onClick={() => this.searchCrew(t.value, time)}
                                           className={t.value == type ? "active " : ""}>&nbsp;{t.name}&nbsp;</a>
                                    </li>
                                }
                            })}
                        </ul>
                    </Flex>

                    <Flex vertical={true} justify={"space-between"} className={"CrewTarget"}>

                        {/*轮播的主要页*/}
                        <Carousel style={{width: "100vh"}}
                                  dots={false}
                                  fade={true}
                                  arrows={true}
                                  prevArrow={<LeftOutlined/>}
                                  nextArrow={<RightOutlined/>}
                                  ref={n => this.carLag = n}
                        >
                            {data2.map((d, i) => {
                                return <div style={{maxHeight:"600px"}}>
                                    <Flex justify={"center"} align={"center"}>
                                        <Image className={"CrewAvatar"} preview={false} src={d.avatar}/>

                                        <Flex align={"center"}>
                                            <h1 className={"CrewName"} style={{"--c2": color}}>
                                                {d.name}
                                            </h1>
                                        </Flex>
                                        <Flex vertical={true} justify={"flex-start"} align={"flex-start"}
                                              style={{height: "100%"}}>
                                            <Flex vertical={true} justify={"flex-end"} className={"CrewSlogan"}
                                                  dangerouslySetInnerHTML={{__html: slogan.text}}></Flex>
                                            <div className={"CrewLine"}>
                                                <div className={"CrewDetail"}
                                                     dangerouslySetInnerHTML={{__html: detail.text}}></div>
                                            </div>
                                        </Flex>
                                    </Flex>
                                    <Flex justify={"center"} style={{color: color}}>{i + 1}</Flex>
                                </div>
                            })}
                        </Carousel>

                        {/*轮播图下面的选择*/}
                        <Flex vertical={true} className={"CrewSmallShell"}>
                            {chunk.map((c, i) => {
                                return <Flex className={"CrewSmallRow"}>
                                    {c.map((d, j) => {
                                        return <Tooltip title={d.name}>
                                            <Flex>
                                                <Image onClick={() => this.carLag.goTo(i * 10 + j)}
                                                       className={"CrewSmall"}
                                                       preview={false} src={d.avatar}/>
                                                <div className={"CrewNameSmall"}
                                                     style={{"--c2": color}}>{d.name}</div>
                                            </Flex>
                                        </Tooltip>
                                    })}
                                </Flex>
                            })}
                        </Flex>

                    </Flex>

                </Flex>

            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Crew)