import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Drawer, Flex, Image, notification, Spin, Tooltip} from "antd";
import Search from "./search/search";
import axios from "axios";
import {DOMAIN, SBSOURCE} from "../../../constant";
import {FieldTimeOutlined, FireTwoTone, QuestionCircleTwoTone, WarningOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import './pocket.css'
import Header2 from "../../../components/Header/header2";

//定义UI组件
class pocket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            width: window.innerWidth,
            vertical: false,
            align: "",

            CHide: true,
            spinning: false,

            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                name: "",
                roomBGImg: "",
                messages: []
            }
        };

        this.handleResize = this.handleResize.bind(this);
    }

    searchMessage = (type, content, time) => {
        axios.post(DOMAIN + '/api/official/pocket', {type, content, time})
            .then(r => {
                const data = r.data.data
                setTimeout(() => {
                    this.setState({data})
                }, 500);
                console.log(data.messages.length / 75)
                this.setState({spinning: true})

                setTimeout(() => {
                    this.setState({spinning: false})
                }, (data.messages.length / 75) * 1000 - 1000);
                console.log('pocket成功=>', r.data.data)
            })
            .catch(e => {
                console.log('pocket失败=>', e)
            })
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        notification.open({
            message: '声明',
            description: '由于不可抗力，本模块只提供口袋两天前的查询，两天内的消息请到口袋查看',
            duration: 3,
            placement: "top",
            icon: <WarningOutlined style={{color: '#f3e932'}}/>,
        });
        this.searchMessage(null, null, dayjs().subtract(2, 'day'))
        this.handleResize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (window.innerWidth < 1000) {
            this.setState({vertical: true, align: "center"})
        } else {
            this.setState({vertical: false, align: ""})
        }
        this.setState({
            width: window.innerWidth,
        });
    }

    render() {

        const {width, vertical, align, CHide, spinning, data} = this.state
        const {name, roomBGImg, messages} = data

        let searchArea = <Search searchMessage={this.searchMessage} close={() => {
        }}/>

        const close = () => {
            this.setState({open: false})
        }

        if (width < 1000) {
            searchArea = <div>
                <Button style={{marginBottom: "10px"}} onClick={() => {
                    this.setState({open: true})
                }}>查询消息记录</Button>
                <Drawer styles={{padding: "0"}} title="查询消息记录" onClose={close} open={this.state.open}
                        placement={"left"}>
                    {<Search searchMessage={this.searchMessage} close={close}/>}
                </Drawer>
            </div>
        }

        const componentType = (type, key, bodys) => {
            switch (type) {
                case 'TEXT':
                    return <div key={key} className={"msgText"}>{bodys.text}</div>
                case 'REPLY':
                    return <div key={key} className={"msgReply"}>
                        <div>{bodys.replyInfo.text}</div>
                        <div style={{borderTop: " 2px solid #000000", borderColor: "rgba(101,101,101,0.77)"}}></div>
                        <div>{bodys.replyInfo.replyName}</div>
                        <div>{bodys.replyInfo.replyText}</div>
                    </div>
                case 'IMAGE':
                    return <Image key={key} width={"50%"} src={bodys.url}/>
                case 'LIVEPUSH':
                    return <Flex vertical={true}
                                 style={{padding: "20px 20px 0", backgroundColor: "white", maxWidth: "70%"}}>
                        <Image preview={false} key={key} src={SBSOURCE + bodys.livePushInfo.liveCover}/>
                        <h5><strong>{bodys.livePushInfo.liveTitle}</strong></h5>
                    </Flex>
                case 'VIDEO':
                    return <video controls key={key} width={"50%"} src={bodys.url}/>
                case 'AUDIO':
                    return <audio controls key={key} width={"50%"} src={bodys.url}/>
                case 'EXPRESSIMAGE':
                    return <Image key={key} width={bodys.expressImgInfo.width}
                                  src={bodys.expressImgInfo.emotionRemote}/>
                case 'FLIPCARD':
                    return <div key={key} className={"msgReply"}>
                        <Flex align={"flex-start"}>
                            <FireTwoTone twoToneColor="red" style={{marginRight: "3px"}}/>
                            <div dangerouslySetInnerHTML={{__html: bodys.filpCardInfo.answer}}></div>
                        </Flex>
                        <hr></hr>
                        <Flex align={"flex-start"}>
                            <QuestionCircleTwoTone style={{marginRight: "3px"}}/>
                            <div dangerouslySetInnerHTML={{__html: bodys.filpCardInfo.question}}></div>
                        </Flex>
                    </div>
                default :
                    if (type.indexOf('RED_PACKET') !== -1) {
                        return <div className={"msgReply"}>红包消息不支持显示，请打开口袋查看</div>
                        // } else if (type.indexOf('FLIPCARD') !== -1) {
                        //     return <div className={"msgReply"}>翻牌消息不支持显示，请打开口袋查看</div>
                    } else if (type.indexOf('GIFTREPLY') !== -1) {
                        return <div className={"msgReply"}>礼物回复消息不支持显示，请打开口袋查看</div>
                    } else {
                        return <div>未知消息类型。请联系管理员查看</div>
                    }
            }
        }


        return (
            <div>
                <Header2 defaultKey={"/pocket"}/>
                <Flex align={align} justify={"space-evenly"} vertical={vertical}
                      style={{padding: "20px 20px 0", backgroundColor: "white"}}>
                    <Spin spinning={spinning} fullscreen/>
                    {searchArea}
                    <Flex vertical={true} className={"room"}>
                        <div style={{fontSize: "20px", margin: "10px 0 0 10px"}}>{name}</div>
                        <Flex style={{margin: "10px 0 10px 10px"}}>
                            <Button style={{borderRadius: "30px", borderColor: "rgba(202, 76, 245, 1)"}}>{name}</Button>
                            {/*<Button></Button>*/}
                            {/*<Button>直播</Button>*/}
                        </Flex>

                        <div className={"essence"} onClick={() => {
                            this.setState({CHide: false})
                        }}>
                            精华(1条) <span><strong>函御:登舰坐标:907971465</strong></span>
                        </div>
                        <Flex className={"essencePopup"} style={{display: CHide ? "none" : "block"}}
                              vertical={true}>
                            <Flex justify={"center"}><strong>精华消息</strong></Flex>
                            <Flex align={"center"} style={{margin: "20px 10px 20px"}}>
                                <img className={"avatar"} style={{marginRight: "10px"}}
                                     src={SBSOURCE + "/avatar/2023/0910/54526095xkpqcjbejsuyf3plm7lz4977.jpg"}/>
                                <div>
                                    <div>函御</div>
                                    <div>23-11-20</div>
                                </div>
                            </Flex>
                            <div style={{fontSize: "15px", marginLeft: "10px"}}>登舰坐标：907971465</div>
                            <Flex justify={"space-between"}>
                                <Button className={"essenceButton"}
                                        style={{color: "red", borderColor: "red"}}
                                        onClick={() => {
                                            this.setState({CHide: true})
                                        }}>关闭</Button>
                                <Button disabled style={{color: "white", backgroundColor: "lightgray"}}
                                        className={"essenceButton"}>下一条</Button>
                            </Flex>
                        </Flex>
                        <Flex className={"roomBG"} style={{backgroundImage: "url(" + roomBGImg + ")"}} vertical={true}>
                            <div></div>
                            {messages.map(m => {
                                const user = m.extinfo.user
                                return <Flex key={m.id} className={"message"}>
                                    <Flex vertical={true}>
                                        {user.pfUrl ? (
                                            <img className={"pf"} src={SBSOURCE + user.pfUrl} alt={"皮肤"}/>) : (
                                            <div></div>)}
                                        <img className={"avatar"} src={SBSOURCE + user.avatar} alt={"头像"}/>
                                    </Flex>
                                    <Flex vertical={true} className={"msgBody"}>
                                        <Flex className={"msgInfo"}>
                                            <div>{user.nickName}</div>
                                            {user.teamLogo ? (
                                                <img className={"team"} src={SBSOURCE + user.teamLogo}
                                                     alt={"队标"}/>) : (
                                                <div></div>)}
                                            <Tooltip title={dayjs.unix(m.msgtime / 1000).format("YYYY-MM-DD HH:mm:ss")}>
                                                <FieldTimeOutlined/>
                                            </Tooltip>
                                        </Flex>
                                        {componentType(m.msgtype, m.id, m.bodys)}
                                    </Flex>
                                </Flex>
                            })}
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(pocket)
