import React, {Component} from 'react';
//引入connect连接
import axios from "axios";
import {DOMAIN} from "../../constant";
import {Flex, Image, Menu} from "antd";
import {connect} from "react-redux";
import './header.css'

//定义UI组件
class Header2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            items: [],
            defaultKey: "",
            data: {
                subzones: [],
                headerBackGround: {},
            }
        };
    }

    getItem = (header) => {
        let items = []
        header.subzones.map(subzone => {
            if (subzone.portrait.link !== null && subzone.portrait.link.length > 0) {
                let icon = ""
                switch (subzone.portrait.link) {
                    case "/letter":
                        icon = "icon-xin"
                        break;
                    case "/timeLine":
                        icon = "icon-peizhitubiaosvg-"
                        break;
                    case "/crew":
                        icon = "icon-chuanyuandianzidangan"
                        break;
                    case "/more":
                        icon = "icon-gengduo"
                        break;
                }
                if (items.length === 3) {
                    items.push({
                        label: <Flex align={"center"} vertical={true} className={"topNAVItem"}>
                            <div style={{height: "20px"}} className={"iconfont icon-jilu"}></div>
                            <div dangerouslySetInnerHTML={{__html: '<p>口袋记录</p>'}}></div>
                        </Flex>,
                        key: '/pocket',
                    })
                }
                let item = {
                    label: <Flex align={"center"} vertical={true} className={"topNAVItem"}>
                        <div style={{height: "20px"}} className={"iconfont " + icon}></div>
                        <div dangerouslySetInnerHTML={{__html: subzone.portrait.text}}></div>
                    </Flex>,
                    key: subzone.portrait.link,
                }
                items.push(item)
            }
        })
        return items
    }

    componentDidMount() {
        axios.get(DOMAIN + '/api/official/header')
            .then(r => {
                const data = r.data.data

                let items = this.getItem(data)
                this.setState({data, items})
                // console.log('header成功=>', r.data.data)
            })
            .catch(e => {
                console.log('header失败=>', e)
            })
    }

    render() {
        const {defaultKey} = this.props
        const {items, data} = this.state
        const {subzones, headerBackGround} = data
        //滑轮，右键，左键，双击左键，移入，移出，键盘事件

        const onClick = (e) => {
            if (!(e.key.includes("tmp"))) {
                window.open(e.key, "_blank")
                this.setState({defaultKey: this.props.defaultKey})
            }
        };

        return (

            <div style={{backgroundColor: "white"}}>
                <Flex className={"topContain"} justify={"center"}>
                    <Image onClick={() => {
                        window.open("/", "_self")
                    }} width={"70px"}
                           preview={false}
                           src={"https://batchspaceshiplog.oss-cn-shanghai.aliyuncs.com/38a2_20240601_xe62qg915kq83d16d8e05esgpom40nw5.png"}/>
                    <Menu className={"topNAV"} defaultSelectedKeys={[defaultKey]} onClick={onClick} mode={"horizontal"}
                          items={items}/>
                </Flex>
                <Flex className={'bgc'} align={"center"} justify={"center"} style={{
                    minHeight: '290px',
                    backgroundImage: "url(" + headerBackGround.resource + ")",
                    paddingBottom: '60px'
                }}>
                    <div></div>
                    {/*<ShockCard subzones={subzones}/>*/}
                </Flex>
            </div>
        );
    }

}

//创建并暴露容器组件
export default connect()(Header2)