import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import './newStar.css'
import {Flex} from "antd";
import Carousel1 from "../../components/Fun/Carousel/1/carousel1";
import Header2 from "../../components/Header/header2";
import axios from "axios";
import {DOMAIN} from "../../constant";
import Carousel3 from "../../components/Fun/Carousel/3/carousel3";
import Carousel2_2 from "../../components/Fun/Carousel/2/carousel2_2";
import Meteor from "../../components/Fun/Background/meteor/meteor";

//定义UI组件
class NewStar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                describe: {},
                title: {},
                content: {},
                videos: [],
                audios: [],
                essays: [],
            }
        };
    }

    componentDidMount() {
        document.title = "新星球"
        axios.get(DOMAIN + '/api/official/newStar')
            .then(r => {
                const data = r.data.data
                // console.log('newStar成功=>', r.data.data)
                this.setState({data})
            })
            .catch(e => {
                console.log('newStar失败=>', e)
            })
    }

    render() {

        const {describe, title, content, videos, audios, essays} = this.state.data

        return (
            <div>
                <Header2/>
                <Meteor height={"1073px"}></Meteor>
                <Flex vertical={true} align={"center"} justify={"center"}>
                    <Carousel1 describe={describe} title={title} content={content} resources={videos}/>
                    <Carousel3 resources={audios}/>
                    <Carousel2_2 resources={essays}/>
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(NewStar)
