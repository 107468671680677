import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Flex} from "antd";
import './Type.css'
import {nanoid} from "nanoid";

//定义UI组件
class Type extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {title, tabs, names, nameByType, contentById} = this.props

        return (
            <Flex className={"typeBG"} align={"center"} vertical={true}>

                <Flex className={"typeHeader"} align={"center"} justify={"space-between"} wrap={"wrap"}>
                    <h1 style={{margin:"0"}}>{title}</h1>
                    <Flex align={"center"} justify={"center"} wrap={"wrap"}>
                        {tabs.map(type => {
                            return <Button style={{margin:"10px"}} key={nanoid()} onClick={() => nameByType(type.id)}>{type.name}</Button>
                        })}
                    </Flex>
                </Flex>

                <Flex className={"typeContent"} wrap={"wrap"} align={"flex-start"} justify={"space-evenly"}>
                    {names.length === 0 ? (<div></div>) : (names.map(letter => {
                        return <Button className={"typeContentButton"} key={nanoid()} title={letter.title}
                                       onClick={() => contentById(letter.id)}>{letter.title}</Button>
                    }))}
                </Flex>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Type)
