import React, {Component} from 'react';
//引入connect连接
import {nanoid} from 'nanoid';
import axios from "axios";
import {DOMAIN} from "../../constant";
import {Button} from "rsuite";
import styled from 'styled-components'
import {Bilibili, Tiktok, Weibo} from '@styled-icons/fa-brands'
import {Flex, Image, Menu, message} from "antd";
import RotateMusic from "../../components/Fun/RotateMusic/rotateMusic";
import './home2.css'
import '../../components/Header/header.css'
import {connect} from "react-redux";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 20px;
  height: 20px;
`
const WB = styled(Weibo)`color: red;
  width: 20px;
  height: 20px;
`
const TK = styled(Tiktok)`color: black;
  width: 20px;
  height: 20px;
`

//定义UI组件
class Home2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: "/",
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                mobileBackgroundOne: {},
                signOne: {},
                platformBackground: {},
                captainPlatforms: [],
            },
            header: {
                subzones: [],
            }
        };
    }

    getItem = (header) => {
        let items = []
        header.subzones.map(subzone => {
            if (subzone.portrait.link !== null && subzone.portrait.link.length > 0) {
                if (items.length === 3) {
                    items.push({
                        label: <div className={"topNAVItem"} dangerouslySetInnerHTML={{__html: '<p className={"topNAVItem"}>口袋记录</p>'}}></div>,
                        key: '/pocket',
                    })

                }
                let item = {
                    label: <div className={"topNAVItem"}
                                dangerouslySetInnerHTML={{__html: subzone.portrait.text}}></div>,
                    key: subzone.portrait.link,
                }
                items.push(item)
            }
        })
        return items
    }


    componentDidMount() {
        message.info('用高分辨率打开本网站体验更好哦~');
        axios.get(DOMAIN + '/api/official/home')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('home成功=>', r.data.data)
            })
            .catch(e => {
                console.log('home失败=>', e)
            })
        axios.get(DOMAIN + '/api/official/header')
            .then(r => {
                const header = r.data.data
                header['items'] = this.getItem(header)
                this.setState({header})
                // console.log('header成功=>', r.data.data)
            })
            .catch(e => {
                console.log('header失败=>', e)
            })
    }


    render() {

        const {current, data, header} = this.state
        const {mobileBackgroundOne, signOne, platformBackground, captainPlatforms} = data
        const {subzones, items} = header

        const onClick = (e) => {
            this.setState({current: e.key})
            window.open(e.key, "_blank")
        };


        const buttonType = (type, text, link) => {
            switch (type) {
                case 'weibo':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<WB/>} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                case 'bilibili':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<B/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                case 'tiktok':
                    return <Button target={'_blank'} href={link} className={'pb'} startIcon={<TK/>}
                                   key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
                default :
                    return <Button target={'_blank'} href={link} className={'pb'} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button>
            }
        }

        return (
            <div>
                <Flex style={{backgroundColor: "white", borderBottom: "1px solid rgba(5, 5, 5, 0.06)",padding:"10px 0"}}
                      justify={"center"}>
                    <Image onClick={()=>{window.open("/","_self")}} width={"70px"}
                           preview={false}
                           src={"https://batchspaceshiplog.oss-cn-shanghai.aliyuncs.com/38a2_20240601_xe62qg915kq83d16d8e05esgpom40nw5.png"}/>
                    <Menu className={"topNAV"} onClick={onClick} mode={"horizontal"} items={items}/>
                </Flex>                <RotateMusic/>
                <Flex className={'bgc'} align={"center"} wrap={"wrap"} justify={"center"} style={{
                    backgroundImage: "url(" + mobileBackgroundOne.resource + ")", minWidth: "100%", minHeight: "900px"
                }}>
                    {signOne !== null ? (<img src={signOne.resource}/>) : (<div></div>)}
                </Flex>
                <Flex align={"center"} justify={"center"} vertical={true} className={'pbGroup bgc'} style={{
                    backgroundImage: "url(" + platformBackground.resource + ")",
                }}>
                    {captainPlatforms.map(p => {
                        return buttonType(p.remark, p.text, p.link)
                    })}
                </Flex>
                {/*<Carousel2_1 resources={subzones}/>*/}
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({
        count: state
    }), null)
(Home2)
