import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Button, Flex, Image} from "antd";
import axios from "axios";
import {DOMAIN} from "../../../constant";
import {nanoid} from "nanoid";
import Header2 from "../../../components/Header/header2";
import './archives.css'
import qs from "qs";
import NormalLink from "../../../components/NormalLink/normalLink";
import {Button as Button2} from "rsuite";
import styled from "styled-components";
import {Bilibili, Tiktok, Weibo} from "@styled-icons/fa-brands";

const B = styled(Bilibili)`color: #FF8CB0;
  width: 20px;
  height: 20px;
`
const WB = styled(Weibo)`color: red;
  width: 20px;
  height: 20px;
`
const TK = styled(Tiktok)`color: black;
  width: 20px;
  height: 20px;
`

//定义UI组件
class Archives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                title: "",
                elementVos: [],
                point: "",
                eventNameVos: [],
            },
        };
    }

    componentDidMount() {
        const {search} = this.props.location
        const {id} = qs.parse(search.slice(1))
        axios.get(DOMAIN + '/api/official/event/' + id)
            .then(r => {
                const data = r.data.data
                document.title = "档案 " + data.title
                this.setState({data})
                // console.log('event成功=>', r.data.data)
            })
            .catch(e => {
                console.log('event失败=>', e)
            })
    }


    render() {
        const {title, elementVos, point, eventNameVos} = this.state.data

        const buttonType = (type, text, link) => {
            switch (type) {
                case 0:
                    return <Button2 target={'_blank'} href={link} className={'apb'} startIcon={<B/>}
                                    key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
                case 1:
                    return <Button2 target={'_blank'} href={link} className={'apb'} startIcon={<WB/>} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
                case 2:
                    return <Button2 target={'_blank'} href={link} className={'apb'} startIcon={<TK/>}
                                    key={nanoid()}><span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
                default :
                    return <Button2 target={'_blank'} href={link} className={'apb'} key={nanoid()}>
                        <span dangerouslySetInnerHTML={{__html: text}}></span>
                    </Button2>
            }
        }

        const mediaType = (title, type, resource, platform, name, link) => {
            switch (type) {
                case 0:
                    return <Flex key={nanoid()} vertical={true} style={{width: "100%"}} align={"center"}>
                        <div className={"elementTitle"}>{title}</div>
                        <iframe className={"elementIframe"} title={title} src={resource}/>
                        <Flex className={"elementIframe"} style={{height: "auto"}} justify={"flex-end"}>
                            {buttonType(platform, name, link)}
                        </Flex>
                    </Flex>
                case 1:
                    return <Flex key={nanoid()} vertical={true} style={{width: "100%"}} align={"center"}>
                        <div className={"elementTitle"}>{title}</div>
                        <Flex className={"elementIframe"} style={{height: "auto"}} justify={"flex-end"}>
                            {buttonType(platform, name, link)}
                        </Flex>
                    </Flex>
                case 2:
                    return <Flex key={nanoid()} vertical={true} style={{width: "100%"}} align={"center"}>
                        <div className={"elementTitle"}>{title}</div>
                        <Image style={{maxWidth: "50vh"}} src={resource}/>
                        <Flex className={"elementIframe"} style={{height: "auto"}} justify={"flex-end"}>
                            {buttonType(platform, name, link)}
                        </Flex>
                    </Flex>
                default :
                    return <div></div>
            }
        }

        return (
            <div>
                <Header2/>

                <Flex className={'archivesTitle'} align={"center"} justify={"center"}>
                    <h1><strong>{title}</strong></h1>
                </Flex>

                <Flex vertical={true} justify={"center"} align={"center"} className={"elementBlock"}>
                    {elementVos.map(item => {
                        return mediaType(item.title, item.type, item.resource, item.platform, item.name, item.link)
                    })}
                </Flex>

                {point ? (
                    <Flex align={"center"} justify={"center"} vertical={true}>
                        <h1>事件重点</h1>
                        <Flex style={{width: '70%', height: '50vh', padding: "30px"}} justify={"flex-start"}
                              align={"flex-start"}
                              className={"elementTodayBlock"}
                              dangerouslySetInnerHTML={{__html: point}}></Flex>
                    </Flex>
                ) : (<div></div>)}

                <Flex justify={"center"} vertical={true} align={"center"}>
                    <h1>本日所有事件</h1>
                    <Flex wrap={"wrap"} align={"center"} className={"elementTodayBlock"} justify={"space-evenly"}>
                        {eventNameVos.map(name => {
                            return <NormalLink style={{width:"30%"}} target={'_blank'} to={"/archives?id=" + name.id}>
                                <div key={nanoid()} className={"elementTodayDiv"}>
                                    {name.title}
                                </div>
                            </NormalLink>
                        })}
                    </Flex>
                </Flex>

                {/*<iframe src={"http://v3-webe.douyinvod.com/deb6e5e7b18f56a36a8295469668c524/66334d46/video/tos/cn/tos-cn-ve-15c001-alinc2/ooDNJ4i9fIcZAXMADmkg1UnUepbQABSFIEYmAJ/?a=6383&ch=10010&cr=3&dr=0&lr=all&cd=0%7C0%7C0%7C3&cv=1&br=2087&bt=2087&cs=0&ds=4&ft=1Tmj0-TAARXkRb6BuCd.FVqBZa-CInKcsltc6BIHyZQyijDHDDQC8Tbj36k8XusZ.&mime_type=video_mp4&qs=0&rc=Zjk3aGk0Njo0PGQ6O2k1NkBpM3lsNGQ6ZmQ3cjMzNGkzM0AyNF9fYzUzNjExY2A1YjVhYSNwYWU0cjRfXmdgLS1kLTBzcw%3D%3D&btag=80000e00010000&cquery=100x_100z_100a_101r_100B&dy_q=1714634531&feature_id=46a7bb47b4fd1280f3d3825bf2b29388&l=2024050215221116F56A2D4EC08565BDC0"}/>*/}


                {/*<img src={"https://wx4.sinaimg.cn/orj360/008nm859ly1hpaetqfq4gj30u013z7b0.jpg"}/>*/}
                {/*<video src={"https://video.weibo.com/media/play?livephoto=https%3A%2F%2Fus.sinaimg.cn%2F001nBrMMjx08e9X9iDuT0f0f01004Iao0k01.mov"} autoPlay onClick={(e)=>{*/}
                {/*    console.log(e.target.play())}}/>*/}

                <Flex justify={"center"}>
                    <Button target={"_blank"} href={"/event"} className={"elementToEvent"}>
                        如想查看更多内容，请点此处前往——>月历
                    </Button>
                </Flex>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Archives)
