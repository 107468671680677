import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Flex} from "antd";
import Introduce from "../../components/Fun/Introduce/Introduce";
import Type from "../../components/Fun/Type/Type";
import axios from "axios";
import {DOMAIN} from "../../constant";
import {nanoid} from "nanoid";
import Header2 from "../../components/Header/header2";
import './letter.css'
import ypz from './ypz.png'
import Star from "../../components/Fun/Background/star/star";

let numArr = []

//定义UI组件
class Letter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // 初始化状态对象，data属性表示保存从服务器获取的数据
            data: {
                titleImg: {},
                introduce: {},
                types: [],
            },
            names: [],
            title: "",
            content: ""
        };
    }

    componentDidMount() {
        document.title = "信"
        axios.get(DOMAIN + '/api/official/letter')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('letter成功=>', r.data.data)
            })
            .catch(e => {
                console.log('letter失败=>', e)
            })
    }

    render() {

        const {titleImg, introduce, types} = this.state.data
        const {names, title, content} = this.state

        const nameByType = (tid) => {
            axios.get(DOMAIN + '/api/official/letterNameByType/' + tid)
                .then(r => {
                    const data = r.data.data
                    this.setState({names: data})
                    // console.log('letterNameByType成功=>', r.data.data)
                })
                .catch(e => {
                    console.log('letterNameByType失败=>', e)
                })
        }

        const contentById = (lid) => {
            axios.get(DOMAIN + '/api/official/letterContentById/' + lid)
                .then(r => {
                    const data = r.data.data
                    this.setState({title: data.title, content: data.content})
                    // console.log('letterContentById成功=>', r.data.data)
                })
                .catch(e => {
                    console.log('letterContentById失败=>', e)
                })
        }

        return (
            <Flex justify={"center"} vertical={true}>
                <Header2 defaultKey={"/letter"}/>
                <Flex align={"center"} vertical={true}>
                    <Flex className={'sectionHeader bgc'} align={"center"} justify={"center"}
                          style={{backgroundImage: "url(" + titleImg.resource + ")"}}>
                        <div dangerouslySetInnerHTML={{__html: titleImg.text}}></div>
                    </Flex>
                    <Flex style={{width: '100%'}} justify={"flex-end"}>
                        <div>
                            本页图
                        </div>
                    </Flex>
                    <Introduce content={introduce.text}/>
                    {
                        types.map(type => {
                            return <Type key={nanoid()} title={type.name} tabs={type.types} names={names}
                                         nameByType={nameByType} contentById={contentById}/>
                        })
                    }
                    <Flex align={"flex-end"} justify={"center"} className={"letterHeader"}><h1>{title}</h1></Flex>

                    {
                        content.length > 5400 ?
                            (content.length > 10800 ? (
                                <div>
                                    <div className={"letter"}
                                         style={{backgroundImage: "url(" + ypz + ")", backgroundSize: "100% 100%"}}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: content.substring(0, content.substring(0, 5400).lastIndexOf("</p>"))}}
                                            className={"letterContent"}></div>
                                    </div>
                                    <div className={"letter"}
                                         style={{backgroundImage: "url(" + ypz + ")", backgroundSize: "100% 100%"}}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: content.substring(content.substring(0, 5400).lastIndexOf("</p>"), content.substring(0, 10800).lastIndexOf("</p>"))}}
                                            className={"letterContent"}></div>
                                    </div>
                                    <div className={"letter"}
                                         style={{backgroundImage: "url(" + ypz + ")", backgroundSize: "100% 100%"}}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: content.substring(content.substring(0, 10800).lastIndexOf("</p>"), content.length)}}
                                            className={"letterContent"}></div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className={"letter"}
                                         style={{backgroundImage: "url(" + ypz + ")", backgroundSize: "100% 100%"}}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: content.substring(0, content.substring(0, 5400).lastIndexOf("</p>"))}}
                                            className={"letterContent"}></div>
                                    </div>
                                    <div className={"letter"}
                                         style={{backgroundImage: "url(" + ypz + ")", backgroundSize: "100% 100%"}}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: content.substring(content.substring(0, 5400).lastIndexOf("</p>"), content.length)}}
                                            className={"letterContent"}></div>
                                    </div>
                                </div>
                            ))
                            : (
                                <div style={{margin:0,padding:0,width:"100%"}}>
                                    <div className={"letter"}
                                         style={{backgroundImage: "url(" + ypz + ")", backgroundSize: "100% 100%"}}>
                                        <div dangerouslySetInnerHTML={{__html: content}} className={"letterContent"}></div>
                                    </div>
                                </div>

                            )
                    }

                </Flex>
            </Flex>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Letter)
