import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'
import {Alert, Button, notification, Popover, Statistic} from "antd";
import dayjs from "dayjs";
import {WarningOutlined} from '@ant-design/icons';

const src = ["monster/", "blackHole/"]
const messages = [
    ["你的请求惊扰了亚空间中的未知生物", "请不要再引起该生物的注意，否则会引发不可预料的结果", "3分钟后飞船护盾将失效，护盾失效后将立刻被该生物识别，请在护盾失效前启动超光速引擎离开该区域", ["未知生物", "403号飞船指挥部将留下，操作飞船机动尽量吸引未知生物注意，为大家的逃生争取更多的时间。"]],
    ["你的请求到达了黑洞附近", "请不要再接近黑洞，避免被黑洞吸入导致飞船被撕碎", "3分钟后将被黑洞捕获，请在3分钟内启动超光速引擎离开该区域", ["黑洞", "403号飞船指挥系统将留下，操作飞船系统尽量为各位注入更多能量，让大家能有望启动超光速引擎逃离黑洞引力范围。"]]
]

let num = Math.random() < 0.5 ? 0 : 1

//定义UI组件
class Unfound extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        notification.open({
            message: '警告！警告！警告！',
            description: messages[num][2],
            duration: 10,
            placement: "top",
            icon: <WarningOutlined style={{color: '#ff0000'}}/>,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    }


    render() {

        const {state} = this.props.location
        if (state !== undefined && state !== null) {
            num = state.n
        }

        const {Countdown} = Statistic;

        return (
            <div>
                <Popover content={messages[num][2]} title="">
                    <Alert
                        style={{height: "25vh", paddingBottom: "10px"}}
                        message={messages[num][0]}
                        description={messages[num][1]}
                        type="error"
                        action={
                            <div>
                                <Countdown title={"距离飞船护盾失效还有"} value={new dayjs() + 180 * 1000}
                                           onFinish={() => {
                                               this.props.history.push("/crush", {typeArr: messages[num][3]})
                                           }}
                                           valueStyle={{color: "red", fontSize: "39px"}}
                                           format="m 分 s 秒"/>
                                <Button onClick={() => {
                                    this.props.history.push("/skip", {type: num})
                                }} style={{backgroundColor: "#000000", color: "white"}}>
                                    启用超光速引擎
                                </Button>
                            </div>
                        }
                    />
                </Popover>
                <iframe src={"http://spaceshiplog.top/unfound/" + src[num]} frameBorder="0" style={{
                    width: "100%",
                    height: "75vh",
                    display: "block"
                }}/>
            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(Unfound)
