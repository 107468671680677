import React, {Component} from 'react';
//引入connect连接
import {connect} from 'react-redux'

import './rotateMusic.css'
import {Button, Flex, Popover, Slider} from "antd";
import axios from "axios";
import {DOMAIN} from "../../../constant";
import {SoundOutlined, StepBackwardOutlined, StepForwardOutlined} from "@ant-design/icons";

let initX = 50;
let initY = 50;

//定义UI组件
class RotateMusic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            play: false,
            data: {
                rotateImg: {},
                musics: []
            }
        }
    }

    componentDidMount() {
        this.volume.volume = 0.5
        axios.get(DOMAIN + '/api/official/music')
            .then(r => {
                const data = r.data.data
                this.setState({data})
                // console.log('music成功=>', r.data.data)
            })
            .catch(e => {
                console.log('music失败=>', e)
            })
    }


    render() {
        const {play, open} = this.state
        const {rotateImg, musics} = this.state.data

        let musicNum = 0;
        const musicArr = musics
        let dropFlag = false;


        const dropMusicStar = (event) => {
            dropFlag = true;
            initX = event.nativeEvent.offsetX;
            initY = event.nativeEvent.offsetY;
        }
        const dropMusicEnd = (event) => {
            dropFlag = false;
        }

        const dropMusic = (event) => {
            if (dropFlag) {
                event.preventDefault();
                let x = event.clientX - initX;
                let y = event.clientY - initY;
                if (x < 0) {
                    x = 0
                }
                if (y < 0) {
                    y = 0
                }
                this.box.style.left = `${x}px`;
                this.box.style.top = `${y}px`;
            }
        }

        const ctlAudio = () => {
            if (this.state.play) {
                this.volume.play()
                this.img.style.animationPlayState = "running"
                this.musicName.style.animationPlayState = "running"
                this.needle.classList.add("needleEnd")
            } else {
                this.volume.pause()
                this.img.style.animationPlayState = "paused"
                this.musicName.style.animationPlayState = "paused"
                this.needle.classList.add("needleStart")
                this.needle.classList.remove("needleEnd")
            }
            this.setState({play: !play})
        }

        const ctlClick = () => {
            this.volume.src = musicArr[0].resource
            this.musicLink.href = musicArr[0].link
            this.musicName.innerHTML = musicArr[0].text
            this.volume.play()
        }

        const ctlVolume = (value) => {
            this.volume.volume = value / 100
        }

        const changeMusic = (num) => {
            // musicNum = musicNum + Math.floor(Math.random() * musicArr.length) + 1
            musicNum = Math.abs((musicNum + num) % musicArr.length)

            this.volume.src = musicArr[musicNum].resource
            this.musicLink.href = musicArr[musicNum].link
            this.musicName.innerHTML = musicArr[musicNum].text

            this.img.style.animationPlayState = "running"
            this.musicName.style.animationPlayState = "running"
            this.needle.classList.add("needleEnd")
            this.volume.play()
        }

        const content = <div className={'volume'}>
            <Slider defaultValue={50} onChange={ctlVolume}/>
        </div>

        return (
            <div className={'rotateBox'} ref={n => this.box = n}>

                <Flex hidden={!open}
                    // onMouseDownCapture={(event) => dropMusicStar(event)}
                    // onMouseUpCapture={(event) => dropMusicEnd(event)}
                    // onMouseMoveCapture={(event) => dropMusic(event)}
                >
                    <Flex className={"rmvml"} justify={"space-evenly"}>

                        <Flex align={"center"} onClick={() => {
                            changeMusic(-1)
                        }}><StepBackwardOutlined style={{fontSize: "20px"}}/>
                        </Flex>

                        <div>
                            <div className={"bgc disc"} onClick={ctlAudio}
                                 style={{backgroundImage: "url(http://batchspaceshiplog.oss-cn-shanghai.aliyuncs.com/disc.png)"}}>
                                <img className={"rotateMusicImg"} draggable={false} src={rotateImg.resource}
                                     ref={n => this.img = n}/>
                                <img className={"needle"} ref={n => this.needle = n}
                                     src={"http://batchspaceshiplog.oss-cn-shanghai.aliyuncs.com/needle.png"}/>
                            </div>

                            <div className="scrolling-text minst">
                                <a target={"_blank"} ref={n => this.musicLink = n}>
                                    <div ref={n => this.musicName = n}></div>
                                </a>
                            </div>
                        </div>

                        <Flex align={"center"} onClick={() => {
                            changeMusic(1)
                        }}><StepForwardOutlined style={{fontSize: "20px"}}/>
                        </Flex>

                        <Popover placement={"bottomRight"} content={content}>
                            <SoundOutlined/>
                        </Popover>

                    </Flex>

                    <audio onEnded={() => changeMusic(1)} ref={n => this.volume = n}></audio>

                </Flex>

                <div hidden={open} className="scrolling-text" onClick={() => {
                    this.setState({open: true})
                }}>
                    <Button type="primary" danger onClick={ctlClick}>
                        <div style={{color: "white"}}>不要点击这里</div>
                    </Button>
                </div>

            </div>
        );
    }

}


//创建并暴露容器组件
export default connect(
    state => ({count: state}), null)
(RotateMusic)
